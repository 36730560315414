import actionCreatorFactory from "typescript-fsa";
import { Templates, Categories, Widgets } from "../../api/comments/api.types";
import { TypeStatusInfo } from "../../constant/constants";
const actionCreator = actionCreatorFactory();

// первый в сагу, второй в редьюсер
type photo = string[];
interface TemplateXml {
  id: number;
  massParam: string[];
}
export const createTemplateData = actionCreator.async<any, any>(
  "createTemplateData"
);
export const createTemplateGroup = actionCreator.async<any, any>(
  "createTemplateGroup"
)
export const updateTemplateGroup = actionCreator.async<any, any>(
  "updateTemplateGroup"
)
export const getStatisticsById = actionCreator.async<any, any>(
  "getStatisticsById"
)
export const deleteTemplateGroup = actionCreator.async<any, any>(
  "deleteTemplateGroup"
)
export const updateTemplateData = actionCreator.async<any, any>(
  "updateTemplateData"
);
export const getPhoto = actionCreator.async<string, any>("GET_PHOTO");
export const setLoader = actionCreator<string>("SET_LOADER");
export const setLoaderTable = actionCreator<boolean>("SET_LOADER_Table");
export const openModal = actionCreator<any>("OPEN_MODAL");
export const closeModal = actionCreator<null>("CLOSE_MODAL");
export const setTableData = actionCreator<any[]>("SET_TABLE_DATA");
export const setTableDataFunc = actionCreator<any>("SET_TABLE_DATA_FUNC");
export const setMap = actionCreator<string | any[]>("SET_MAP");
export const fetchCategory = actionCreator.async<string, photo[]>(
  "FETCH_CATEGORY"
);
export const setLocation = actionCreator<any>("SET_LOCATION");
export const logging = actionCreator.async<
  { email: string; password: string },
  any
>("logging");
export const getTemplate = actionCreator.async<
  {
    userId: string;
    isActive: boolean;
    all: boolean;
    sort?: any[];
    idXml?: string;
    page?: string;
    limit?: string;
    isPhotos?: boolean;
  },
  Templates[]
>("getTemplate");

export const createDataXml = actionCreator.async<any, Templates[]>(
  "createDataXml"
);
export const getTemplateCreate = actionCreator.async<{}, Templates[]>(
  "getTemplateCreate"
);

export const saveTemplate = actionCreator.async<
  { id: number; userId: string },
  string
>("saveTemplate");
export const deleteTemplate = actionCreator.async<
  { id: number; userId: string },
  string
>("deleteTemplate");

export const clearCategory = actionCreator<any>("clearCategory");

export const getCategory = actionCreator.async<
  { id: number; index: number },
  Categories[]
>("getCategory");

export const paginateTable = actionCreator.async<
  { page: string; id: string; updateTemplate: any[]; newPage: string },
  any[]
>("paginateTable");

export const paginateTableGet = actionCreator.async<
  { page: string; id: string },
  any[]
>("paginateTableGet");

export const setCreateTemplateTable = actionCreator<any>("SET_TEMPLATE_TABLE");

export const fetchCreateTemplate = actionCreator.async<
  {
    page?: string;
    params: string;
    name: string;
    userId: number;
    idCategory: number;
    isActive: number;
    count: number;
    originParams: string;
    photo?: string;
    photoLink?: any[];
    id: number;
    isDescription?: number;
  },
  photo[]
>("fetchCreateTemplate");

export const setTemplateXml = actionCreator<{
  date: string;
  id: string;

  value: { index: number; result: any; saveDateTime: any };
}>("SET_TEMPLATE_XML");
export const clearTemplateXml = actionCreator<undefined>("CLEAR_TEMPLATE_XML");
export const uploadFile = actionCreator.async<
  {
    urlLink: string;
    file: any;
    userId: string;
    onUploadProgress: any;
    idCategory?: number;
    id: number;
  },
  string[]
>("uploadFile");

export const createXml = actionCreator.async<
  {
    socketId: string;
    file: any;
    userId: number;
    name: string;
    templates: TemplateXml[];
    isUpdate: boolean;
    idXml: number;
    count: number;
  },
  string
>("createXml");
export const deleteXml = actionCreator.async<
  { userId: string; xmlId: string },
  any[]
>("deleteXml");
export const sharedXml = actionCreator.async<
  { userId: number; id: number; isAvito: boolean },
  string
>("sharedXml");
export const deleteTemplateList = actionCreator<number>("DELETE_TEMPLATE_XML");
export const getXmlList = actionCreator.async<
  { userId: string; page?: string; limit?: string },
  any[]
>("getXmlList");
export const register = actionCreator.async<
  { email: string; password: string; name: string; checkEmail: boolean },
  any
>("register");
export const setInfoStatus = actionCreator<{
  type: TypeStatusInfo;
  text: string;
} | null>("setInfoStatus");
export const setEditXml = actionCreator<any | null>("setEditXml");
export const сlearTemplateXml = actionCreator("сlearTemplateXml");
export const getWidgets = actionCreator.async<undefined, Widgets>("getWidgets");
export const setOpenCreateTemlate = actionCreator<{
  value: string;
  idCategory: number;
} | null>("setOpenCreateTemlate");
export const setPhotoList = actionCreator<string[] | null>("setPhotoList");
export const setPhoto = actionCreator<string>("setPhoto");
export const setIdEdit = actionCreator<number | null>("setIdEdit");
export const isDescription = actionCreator<number | null>("isDescription");
export const setStatus = actionCreator<string | null>("setStatus");
export const getTemplateById = actionCreator.async<
  { id: number; idCategory: number },
  any
>("getTemplateById");
export const setUser = actionCreator<any>("setUSer");
export const setToken = actionCreator<string>("setToken");
export const getActiveXml = actionCreator.async("getActiveXml");
export const sendKey = actionCreator.async<{ email: string }, any>("sendKey");
export const refreshPassword = actionCreator.async<
  { email: string; password: string; key: string },
  any
>("refreshPassword");
export const buy = actionCreator.async<
  {
    userId: number;
    cloud: number;
    ads: number;
    xml: number;
    orderNumber: string;
    price?: string;
    isNew?: boolean;
  },
  any
>("buy");

export const buyCloud = actionCreator<any>("buyCloud");
