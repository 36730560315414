import lodash, { cloneDeep } from "lodash";
import {
  getPhoto,
  setLoader,
  openModal,
  closeModal,
  setTableData,
  setTableDataFunc,
  setMap,
  setLocation,
  logging,
  getTemplate,
  getCategory,
  setCreateTemplateTable,
  setTemplateXml,
  clearTemplateXml,
  uploadFile,
  getXmlList,
  deleteTemplateList,
  setInfoStatus,
  setEditXml,
  сlearTemplateXml,
  getWidgets,
  setOpenCreateTemlate,
  setPhotoList,
  setIdEdit,
  isDescription,
  setStatus,
  getTemplateById,
  setUser,
  getActiveXml,
  buyCloud,
  createTemplateData,
  getTemplateCreate,
  clearCategory,
  setLoaderTable,
  paginateTable,
  paginateTableGet,
  setPhoto,
  setToken,
} from "../actions/actions";
import { createReducer } from "redux-create-reducer";
import { Templates, Widgets } from "../../api/comments/api.types";
import { getCookie } from "../../helpers/cookies";
import { TypeStatusInfo } from "../../constant/constants";
import { getAccessToken, isLoggedIn } from "../../api/auth/tokens";
type photo = string[];

export type TypeState = {
  loaderName: string;
  photoLink: photo[] | any;
  photo: string;
  listTemplate: Templates[];
  globalModal?: {
    title: string;
    save: any;
    setOpen: (value: boolean) => void;
    component: JSX.Element;
    idCategory: number;
  };
  user: any;
  tableData?: any[];
  tableDataFunc?: () => any[];
  map?: any[] | string | number;
  location?: {
    value: string[];
    type: string;
  };
  category: any;
  subCategory: any;
  itemsCategory: any;
  objectCategory: any;
  serviceCategory: any;
  createTemplateTable: any[];
  templateXMLStatus: boolean;
  templateXML: any[any];
  xmlList: any[];
  message: { type: TypeStatusInfo; text: string } | null;
  editXml: any | null;
  widgets: Widgets | null;
  createTemlateModal: any | null;
  idEdit: number;
  isDescription: number | null;
  status: string | null;
  getActiveXml: any;
  buyCloud?: any;
  createTemplateData?: any;
  createTemplateDataTemplateId?: any;
  createTemplateDataCount?: any;
  createTemplateDataLoader?: boolean | null;
};
const token = isLoggedIn() ? getAccessToken() : "";
const email = "";
const userId = "";
export const initialState = {
  buyCloud: null,
  loaderName: "",
  photoLink: [],
  photo: "",
  user: { token, email, userId },
  listTemplate: [],
  category: {},
  subCategory: {},
  itemsCategory: {},
  objectCategory: {},
  serviceCategory:{},
  createTemplateTable: [],
  templateXML: [],
  templateXMLStatus: false,
  xmlList: [],
  message: null,
  editXml: null,
  widgets: null,
  createTemlateModal: null,
  idEdit: 0,
  isDescription: null,
  status: null,
  getActiveXml: [],
  createTemplateData: null,
  createTemplateDataCount: null,
  createTemplateDataTemplateId: null,
  createTemplateDataLoader: null,
};

export const Reducer = createReducer<TypeState, any>(initialState, {
  [setUser.type]: (state: TypeState, action: ReturnType<typeof setUser>) => {
    return {
      ...state,
      user: action.payload,
    };
  },
  [setToken.type]: (state: TypeState, action: ReturnType<typeof setToken>) => {
    return {
      ...state,
      user: {
        ...state.user,
        token: action.payload,
      },
    };
  },
  [buyCloud.type]: (state: TypeState, action: ReturnType<typeof buyCloud>) => {
    return {
      ...state,
      buyCloud: action.payload,
    };
  },

  [setLoader.type]: (
    state: TypeState,
    action: ReturnType<typeof setLoader>
  ) => {
    return {
      ...state,
      loaderName: action.payload,
    };
  },

  [setLoaderTable.type]: (
    state: TypeState,
    action: ReturnType<typeof setLoaderTable>
  ) => {
    return {
      ...state,
      createTemplateDataLoader: action.payload,
    };
  },

  [createTemplateData.done.type]: (
    state: TypeState,
    action: ReturnType<typeof createTemplateData.done>
  ) => {
    return {
      ...state,
      createTemplateData: action.payload.result?.data?.data || null,
      createTemplateDataCount: action.payload.result?.data?.count || null,
      createTemplateDataTemplateId: action.payload.result?.data?.templateId,
      // loaderName: action.payload,
    };
  },

  [paginateTable.done.type]: (
    state: TypeState,
    action: ReturnType<typeof paginateTable.done>
  ) => {
    return {
      ...state,
      createTemplateData: action?.payload?.result || null,

      // loaderName: action.payload,
    };
  },

  [paginateTableGet.done.type]: (
    state: TypeState,
    action: ReturnType<typeof paginateTableGet.done>
  ) => {
    const arr = Array.from(action?.payload?.result);
    return {
      ...state,
      createTemplateData: arr?.length
        ? arr?.map((i) => {
          if (i?.row) {
            return i;
          } else return { row: i };
        })
        : null,
      createTemplateDataLoader: arr?.length ? null : true,
      // loaderName: action.payload,
    };
  },

  [getTemplateById.done.type]: (
    state: TypeState,
    action: ReturnType<typeof getTemplateById.done>
  ) => {
    const idCategory = action.payload.result.idCategory
      ? { idCategory: action.payload.result.idCategory }
      : {};

    let editValue = {};
    if (action.payload.result.originParams) {
      editValue = JSON.parse(action.payload.result.originParams);
    }

    const result = {
      ...state.createTemlateModal,
      ...idCategory,
      field: action.payload.result.field,
      params: action.payload.result.params,
      // idCategory: action.payload.result.idCategory,
      editValue,
      idXml: action?.payload?.result?.xmlId,
    };
    return {
      ...state,
      createTemlateModal: result,
      // loaderName: action.payload,
    };
  },

  [setStatus.type]: (
    state: TypeState,
    action: ReturnType<typeof setLoader>
  ) => {
    return {
      ...state,
      status: action.payload,
    };
  },

  [isDescription.type]: (
    state: TypeState,
    action: ReturnType<typeof isDescription>
  ) => {
    return {
      ...state,
      isDescription: action.payload,
    };
  },

  [setOpenCreateTemlate.type]: (
    state: TypeState,
    action: ReturnType<typeof setOpenCreateTemlate>
  ) => {
    return {
      ...state,
      createTemlateModal: action.payload,
    };
  },

  [getActiveXml.done.type]: (
    state: TypeState,
    action: ReturnType<typeof getActiveXml.done>
  ) => {
    return {
      ...state,
      getActiveXml: action.payload.result,
    };
  },
  [getWidgets.done.type]: (
    state: TypeState,
    action: ReturnType<typeof getWidgets.done>
  ) => {
    return {
      ...state,
      widgets: action.payload.result,
    };
  },
  [setPhotoList.type]: (
    state: TypeState,
    action: ReturnType<typeof setPhotoList>
  ) => {
    return {
      ...state,
      photoLink: action.payload,
    };
  },

  [setPhoto.type]: (state: TypeState, action: ReturnType<typeof setPhoto>) => {
    return {
      ...state,
      photo: action.payload,
    };
  },

  [сlearTemplateXml.type]: (state: TypeState) => {
    return {
      ...state,
      templateXML: [],
      templateXMLStatus: false,
      editXml: null,
    };
  },

  [setEditXml.type]: (
    state: TypeState,
    action: ReturnType<typeof setEditXml>
  ) => {
    return {
      ...state,
      editXml: action.payload,
    };
  },
  [getXmlList.done.type]: (
    state: TypeState,
    action: ReturnType<typeof getXmlList.done>
  ) => {
    return {
      ...state,
      xmlList: action.payload.result,
    };
  },
  [setInfoStatus.type]: (
    state: TypeState,
    action: ReturnType<typeof setInfoStatus>
  ) => {
    return {
      ...state,
      message: action.payload,
    };
  },

  [setTemplateXml.type]: (
    state: TypeState,
    action: ReturnType<typeof setTemplateXml>
  ) => {
    const list = cloneDeep(state.templateXML);
    // [{value:[{date:{monthCurrent, yearCurrent}, value:[1-30] }], id}]

    const index = list?.findIndex((item) => item?.id == action.payload.id);
    let data = index >= 0 ? list[index] : [];
    const dadada: any = action.payload.date;

    let findData = data?.value?.findIndex((i) => {
      return (
        i?.date.monthCurrent == dadada.monthCurrent &&
        i?.date.yearCurrent == dadada.yearCurrent
      );
    });
    // console.log(action.payload);
    if (findData >= 0) {
      list[index].value[findData].value = action.payload.value.result;
      list[index].value[findData].saveDateTime =
        action.payload.value.saveDateTime;
    } else {
      if (index >= 0) {
        const data = {
          date: action.payload.date,
          value: action.payload.value.result,
          saveDateTime: action.payload.value.saveDateTime,
        };

        list[index]?.value?.push(data);
      } else {
        const data = {
          value: [
            {
              date: action.payload.date,
              value: action.payload.value.result,
              saveDateTime: action.payload.value.saveDateTime,
            },
          ],
          id: action.payload.id,
        };
        list.push(data);
      }
    }

    // if (list.length <= action.payload.value.index) {
    //   list.push(action.payload.value.result);
    // } else {
    //   const dadada: any = action.payload.date;

    //   const index = list.findIndex(item=>item.id ==action.payload.id )
    //   console.log('redux',index )
    //   const isList = list[index]?.value?.findIndex((i) => {
    //     return (
    //       i?.date.monthCurrent == dadada.monthCurrent &&
    //       i?.date.yearCurrent == dadada.yearCurrent
    //     );
    //   });
    //   if (isList > -1) {
    //     const newValue = list[index].value;
    //     newValue[isList] = {
    //       date: action.payload.date,
    //       value: action.payload.value.result,
    //       id:action.payload.id,
    //     };
    //     list[index] = { value: newValue ,  id:action.payload.id,};
    //   } else {
    //     const newValue = list[action.payload.value.index].value;
    //     newValue?.push({
    //       id:action.payload.id,
    //       date: action.payload.date,
    //       value: action.payload.value.result,
    //     });
    //   }
    // }
    return {
      ...state,
      templateXML: list,
      templateXMLStatus: true,
    };
  },
  [deleteTemplateList.type]: (
    state: TypeState,
    action: ReturnType<typeof deleteTemplateList>
  ) => {
    let list = cloneDeep(state.templateXML);
    //delete list[action.payload]
    list = list.filter((item, index) => index !== action.payload);
    return {
      ...state,
      templateXML: list,
    };
  },
  [clearTemplateXml.type]: (
    state: TypeState,
    action: ReturnType<typeof clearTemplateXml>
  ) => {
    return {
      ...state,
      templateXMLStatus: false,
      templateXML: [],
    };
  },

  [setCreateTemplateTable.type]: (
    state: TypeState,
    action: ReturnType<typeof setCreateTemplateTable>
  ) => {
    return {
      ...state,
      createTemplateTable: action.payload,
    };
  },

  [getTemplate.done.type]: (
    state: TypeState,
    action: ReturnType<typeof getTemplate.done>
  ) => {
    let res = Object.values(action.payload.result).flat();
    if (Array.isArray(action.payload.params?.sort)) {
      const newRes: Templates[] = [];
      action.payload.params?.sort.forEach((item) => {
        const find = res.find((i) => i?.id === item?.id);
        if (find) newRes.push(find);
      });
      res.forEach((item) => {
        if (!newRes.find((i) => i?.id === item?.id)) {
          newRes.push(item);
        }
      });
      res = newRes;
    }
    return {
      ...state,
      listTemplate: res,
    };
  },
  [getTemplateCreate.done.type]: (state: TypeState, action: any) => {
    let res = action.payload.result;
    // if (Array.isArray(action.payload.params?.sort)) {
    //   const newRes: Templates[] = [];
    //   action.payload.params?.sort.forEach((item:any) => {
    //     const find = res.find((i:any) => i?.id === item?.id);
    //     if (find) newRes.push(find);
    //   });
    //   action.payload.result.forEach((item:any) => {
    //     if (!newRes.find((i) => i?.id === item?.id)) {
    //       newRes.push(item);
    //     }
    //   });
    //   res = newRes;
    // }
    return {
      ...state,
      listTemplate: res,
    };
  },
  [uploadFile.done.type]: (
    state: TypeState,
    action: ReturnType<typeof getPhoto.done>
  ) => {
    const edit = action.payload.result.id
      ? { idEdit: action.payload.result.id }
      : {};
    const status =
      action.payload.result?.length == 0 && !action.payload.result.id
        ? { status: null, message: null }
        : {};
    return {
      ...state,
      photoLink: action.payload.result.result,
      ...edit,
      ...status,
      // createTemlateModal: {
      //   value: "edit",
      //   idCategory:action.payload.result.idCategory,
      //   id:action.payload.result.id
      // },
    };
  },
  [setIdEdit.type]: (
    state: TypeState,
    action: ReturnType<typeof setIdEdit>
  ) => {
    return {
      ...state,
      idEdit: action.payload || 0,
    };
  },

  // [getPhoto.done.type]: (
  //   state: CommentsTypeState,
  //   action: ReturnType<typeof getPhoto.done>
  // ) => {
  //   return {
  //     ...state,
  //     photoLink: action.payload.result,
  //   };
  // },
  [openModal.type]: (
    state: TypeState,
    action: ReturnType<typeof openModal>
  ) => {
    return {
      ...state,
      globalModal: action.payload,
    };
  },
  [closeModal.type]: (
    state: TypeState,
    action: ReturnType<typeof closeModal>
  ) => {
    return {
      ...state,
      globalModal: undefined,
    };
  },
  [setTableData.type]: (
    state: TypeState,
    action: ReturnType<typeof setTableData>
  ) => {
    return {
      ...state,
      tableData: action.payload,
    };
  },
  [setTableDataFunc.type]: (
    state: TypeState,
    action: ReturnType<typeof setTableDataFunc>
  ) => {
    return {
      ...state,
      tableDataFunc: action.payload,
    };
  },
  [setMap.type]: (
    state: TypeState,
    action: ReturnType<typeof setTableDataFunc>
  ) => {
    return {
      ...state,
      map: action.payload,
    };
  },

  [clearCategory.type]: (
    state: TypeState,
    action: ReturnType<typeof clearCategory>
  ) => {
    return {
      ...state,
      subCategory: [],
      itemsCategory: [],
      objectCategory: [],
      serviceCategory:[]
    };
  },

  [getCategory.done.type]: (
    state: TypeState,
    action: ReturnType<typeof getCategory.done>
  ) => {
    let result;
    if (action.payload.params.index === 0) {
      result = {
        category: action.payload.result,
        subCategory: [],
        itemsCategory: [],
        objectCategory: [],
        serviceCategory:[]
      };
      result.category.unshift({ id: 0, value: "", parent: 0 });
    }
    if (action.payload.params.index === 1) {
      result = {
        subCategory: action.payload.result,
        itemsCategory: [],
        objectCategory: [],
        serviceCategory:[]
      };
      result.subCategory.unshift({ id: 0, value: "", parent: 0 });
    }
    if (action.payload.params.index === 2) {
      result = { itemsCategory: action.payload.result, objectCategory: [], serviceCategory:[] };
      result.itemsCategory.unshift({ id: 0, value: "", parent: 0 });
    }
    if (action.payload.params.index === 3) {
      result = { objectCategory: action.payload.result, serviceCategory:[] };
      result.objectCategory.unshift({ id: 0, value: "", parent: 0 });
    }

    if (action.payload.params.index === 4) {
      result = { serviceCategory: action.payload.result,  };
      result.serviceCategory.unshift({ id: 0, value: "", parent: 0 });
    }

    return {
      ...state,
      ...result,
    };
  },

  [setLocation.type]: (
    state: TypeState,
    action: ReturnType<typeof setLocation>
  ) => {
    const locationNew = lodash.uniqWith(action.payload?.value, lodash.isEqual);
    return {
      ...state,
      location: action.payload,
    };
  },
  [logging.done.type]: (
    state: TypeState,
    action: ReturnType<typeof logging.done>
  ) => {
    return {
      ...state,
      user: action.payload.result,
    };
  },
});
