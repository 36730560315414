import { AxiosInstance } from 'axios';
import {
  Comment,
  DirYa,
  Templates,
  Categories,
  CreateTemplate,
  Widgets,
} from './api.types';
import { url, Loggin } from '../../constant/constants';
import uniqid from 'uniqid';

const key = require('./striped-weaver-372121-c9cf276be1b5.json');

interface Props {
  HTTPBaseURL: AxiosInstance;
}

const uploadFileToS3 = async (presignedPostData: any, file: any) => {
  const formData = new FormData();
  Object.keys(presignedPostData.fields).forEach((key) => {
    formData.set(key, presignedPostData.fields[key]);
  });
  formData.set('file', file);

  const response = await fetch(presignedPostData.url, {
    method: 'post',
    body: formData,
  });
};

const ServiceFactory = ({ HTTPBaseURL }: Props) => {
  return {
    getInstructions: async () => {
      const result = await HTTPBaseURL.get(`${url}/instructions/getInstructions`);
      return result.data;
    },

    createInstruction: async (
      name: string,
      text?: string,
      videoUrl?: string
    ) => {
      const result = await HTTPBaseURL.post(`${url}/instructions/createInstruction`, {
        name,
        text,
        videoUrl,
      });

      return result.data;
    },

    getComments: async (): Promise<Comment[]> => {
      const result = await HTTPBaseURL.get(`comments`);
      return result?.data; //.then((res: any) => res.data);
    },
    getPhoto: (path: string): Promise<DirYa> => {
      return HTTPBaseURL.get(`/resources?public_key=${path}`).then(
        (res) => res.data
      );
    },
    getPhotoFile: async (
      url: string,
      dir: string,
      offset?: number,
      limit?: number
    ): Promise<any> => {
      const result = await HTTPBaseURL.get(
        `/resources?public_key=${url}&path=${dir}${
          offset || offset === 0 ? `&offset=${offset}` : ''
        }${limit || limit ? `&limit=${limit}` : ''}`
      );
      return result?.data;
    },

    getCategories: async (id?: number): Promise<Categories[]> => {
      const result = await HTTPBaseURL.get(
        `${url}/category/getCategoriesById${id ? `?id=${id}` : ''}`,
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );
      return result?.data;
    },
    getActiveXml: async (): Promise<Categories[]> => {
      const result = await HTTPBaseURL.get(`${url}/xml-files/getActiveXml`, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      });
      // console.log(result.data.result);
      result.data.result[0].isAvito = 1;
      return result?.data.result;
    },

    paginateTable: async (templateId, page, updateTemplate): Promise<any[]> => {
      const data = {
        templateId,
        page,
        updateTemplate: updateTemplate?.map((item) => {
          if (item?.row) {
            return item;
          } else return { row: item };
        }),
      };
      const result = await HTTPBaseURL.post(`${url}/template/updateMok`, data, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      });
      return result?.data;
    },

    paginateTableGet: async (templateId, page): Promise<any> => {
      const data = { templateId, page };
      const result = await HTTPBaseURL.post(`${url}/template/updateMokGet`, data, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      });
      // console.log(result.data.data)
      return result.data;
    },

    buy: async (data: any): Promise<boolean> => {
      const result = await HTTPBaseURL.post(`${url}/updateTarif`, data, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      });
      return result?.data;
    },

    uploadPhoto: async (
      urlLink: string,
      userId: string,
      idCategory: number,
      id: number,
      onUploadProgress: any
    ): Promise<string> => {
      var formData = new FormData();
      // const files:any[] = file;
      // const src = [];

      // const names:any [] = [];
      // for (var i = 0; i < files.length; i++) {
      //   names.push(files[i].webkitRelativePath);
      // }
      // const body = JSON.stringify({
      //   url:urlLink,
      //   token: "Bearer " + getCookie("authorization"),
      // });

      const res = await HTTPBaseURL.post(
        `${url}/template/uploadDrive`,
        {
          url: urlLink,
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );

      const templateId = uniqid();

      const photo2 = await HTTPBaseURL.post(
        url + '/template/upload',
        {
          id: id.toString(),
          photo: res?.data,
          idCategory: idCategory.toString(),
          userId: userId,
          templateId: templateId,
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );

            return photo2?.data || null;
    },
    getUserCloud: async (id: string): Promise<number> => {
      const result = await HTTPBaseURL.get(`${url}/userCloud?userId=${id}`, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      });
      return result?.data?.response;
    },

    createXml: async (xmlData: any): Promise<Loggin> => {
      const result = await HTTPBaseURL.post(`${url}/xml-files/createXml`, xmlData, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      });
      return result?.data;
    },
    createTemplateData: async (xmlData: any): Promise<Loggin> => {
      const result = await HTTPBaseURL.post(`${url}/newTemplate`, xmlData);
      return result?.data;
    },

    createTemplateGroup: async (templateGroupData: any): Promise<Loggin> => {
      const result = await HTTPBaseURL.post(`${url}/template-group/createTemplateGroup`, templateGroupData);
      return result?.data;
    },

    updateTemplateGroup: async (templateGroupData: any): Promise<Loggin> => {
      const result = await HTTPBaseURL.patch(`${url}/template-group/updateTemplateGroup`, templateGroupData);
      return result?.data;
    },
    deleteTemplateGroup: async (groupId: string): Promise<Loggin> => {
      const result = await HTTPBaseURL.delete(`${url}/template-group/deleteTemplateGroup/${groupId}`);
      return result?.data;
    },
    getStatisticsById: async (templateData: any): Promise<any> => {
      const result = await HTTPBaseURL.post(`${url}/statistics/getStatisticsById`, {
        userId: templateData.userId,
        id: templateData.id
      });
      return result?.data;
    },
    updateTemplateData: async (xmlData: any): Promise<Loggin> => {
      const result = await HTTPBaseURL.post(
        `${url}/updateNewTemplate`,
        xmlData
      );

      return result?.data;
    },

    sharedXml: async (value: {
      id: number;
      userId: number;
      isAvito: boolean;
    }): Promise<string> => {
      const result = await HTTPBaseURL.post(`${url}/xml-files/shared`, value, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      });
      return result?.data;
    },

    logging: async (email: string, password: string): Promise<Loggin> => {
      const user = {
        email,
        password,
      };
      const result = await HTTPBaseURL.post(`${url}/auth/login`, user, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      });
      return result?.data;
    },
    getTemplate: async (
      id: string,
      isActive: boolean,
      all: boolean,
      idXml?: string,
      page?: string,
      limit?: string,
      isPhotos?: boolean
    ): Promise<any> => {
      const result = await HTTPBaseURL.get(
        `${url}/template/getTemplate?userId=${id}&&all=${!!all}&isActive=${
          isActive ? true : ''
        }${idXml ? `&idXml=${idXml}` : ''}${page ? `&page=${page}` : ''}${
          limit ? `&limit=${limit}` : ''
        }&isPhotos=${!!isPhotos}`
      );
      return result?.data;
    },
    getTemplateCreate: async (): Promise<Templates[]> => {
      const result = await HTTPBaseURL.get(`${url}/template/getTemplateCount`);
      return result?.data;
    },
    getXMLData: async (params: any): Promise<any> => {
      const result = await HTTPBaseURL.get(`${url}/template/getTemplateCount`);
      return result?.data;
    },
    getTemplateById: async ({ id, idCategory }: any): Promise<any[]> => {
      const result = await HTTPBaseURL.get(
        `${url}/template/getTemplateById?id=${id}&idCategory=${idCategory}`
      );      
      return result?.data;
    },
    getWidget: async (): Promise<Widgets> => {
      const result = await HTTPBaseURL.get(`${url}/template/getwidgets`).then(
        (res) => res
      );
      return result?.data;
    },

    getxml: async (id: string, page?: string, limit?: string): Promise<any> => {
      const result = await HTTPBaseURL.get(
        `${url}/xml-files/getXmlList?userId=${id}${page ? `&page=${page}` : ''}${
          limit ? `&limit=${limit}` : ''
        }`
        );
      return result?.data.result;
    },
    deleteXml: async (userId: string, xmlId: string): Promise<Loggin> => {
      const data = {
        userId,
        xmlId,
      };
      const result = await HTTPBaseURL.post(`${url}/xml-files/deleteXml`, data, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      });
      return result?.data;
    },

    register: async (value: {
      email: string;
      password: string;
      name: string;
      checkEmail: boolean;
    }): Promise<any> => {
      const result = await HTTPBaseURL.post(
        `${url}/auth/signup`,
        { ...value, repeatPassword: value.password },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );
      return result?.data;
    },

    deleteTemplate: async (id: number): Promise<string> => {
      const result = await HTTPBaseURL.delete(
        `${url}/template/deleteTemplate/?id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );
      return result?.data;
    },

    sendKey: async (email: string): Promise<string> => {
      const result = await HTTPBaseURL.post(
        `${url}/auth/sendKey`,
        { email },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );
      return result?.data;
    },
    refreshPassword: async (data: {
      email: string;
      password: string;
      key: string;
    }): Promise<string> => {
      const result = await HTTPBaseURL.post(`${url}/auth/resetPassword`, data, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      });
      return result?.data;
    },

    startTemplateCreation: async ({
      socketId,
      field,
      formValue,
      length,
      adressList,
      photo,
      templateId,
      name,
      isActive,
      originParams,
      photoLink,
      isDescription,
      isUpdate,
    }) => {
      const result = await HTTPBaseURL.post(
        `${url}/template/createTemplate`,
        {
          // params,
          socketId,
          field,
          formValue,
          length,
          addressList:adressList,
          photo,
          templateId,
          name,
          isActive,
          originParams,
          photoLink,
          isDescription,
          isUpdate,
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );
      return result?.data;
    },

    updateTemplateByMok: async ({ templateId }) => {
      const result = await HTTPBaseURL.post(
        `${url}/template/updateMokGet`,
        {
          templateId,
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );
      return result?.data;
    },

    selectXml: async (data: {
      xmlId: string;
      searchText?: string;
      page?: number;
      limit?: number;
    }) => {
      const { xmlId, searchText, page, limit } = data;
      const result = await HTTPBaseURL.get(
        `${url}/xml-files/parseXml?id=${xmlId}${page ? `&page=${page}` : ''}${
          limit ? `&limit=${limit}` : ''
        }${searchText ? `&searchText=${searchText}` : ''}`,

        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );      
      return result;
    },

    createTemplate: async ({
      params,
      name,
      userId,
      isActive,
      count,
      originParams,
      photoLink,
      id,
      isDescription = 0,
    }: CreateTemplate): Promise<string> => {
      const result = await HTTPBaseURL.post(
        `${url}/createTemplate`,
        {
          // params,
          name,
          userId,
          isActive,
          count,
          originParams,
          photoLink,
          id,
          isDescription,
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );
      return result?.data;
    },


    getTemplateXML: async (urlXML: string) => {
      const result = await HTTPBaseURL.post(
        `${url}/xml-files/proxy`,
        {
          "link":urlXML
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );
      return result;
    },
    getXMLByLink: async (urlXML: string) => {
      const result = await HTTPBaseURL.post(
        `${url}/xml-files/proxy`,
        {
          "link":urlXML
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      );
      return result;
    },
    // createXmlFile: async (templateXML: any) => {
    //   const result = await HTTPBaseURL.post(`${url}/create-xml-file`, {
    //     templateXML,
    //   });
    //   return result?.data;
    // },
  };
};

export default ServiceFactory;
